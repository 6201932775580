:root {
    /** Font  size */
    font-size: 10px;

    /** Page Margin */
    --pageMargin: 15px;

    /** Provided colour pallette */
    --primaryBP: #116AA4;
    --primaryBPTint: #20AAFF;
    --primaryBPContrast: #FFFFFF;
    --secondaryBP: #000000;
    --secondaryBPTint: #333238;
    --successBP: #5cB85C;
    --dangerBP: #FF7052;

    /** Other colours */
    --logoGrey: #333333;
    --inputBackground: #daf0fd;
    --ossLabelColor: #737373;
    --ossPlaceholderColor: #9e9e9e;
    --darkGreyTA: grey;
    --lightGreyTA: lightgrey;


    /** Matrix colours */
    --matrixLow:#0EB500;
    --matrixMedium:#FFC738;
    --matrixHigh:#FF9838;
    --matrixExtreme:red;

    --cardNearMiss:#0EB500;
    --cardMinorInjury:#FFC738;
    --cardModerateInjury:#FFC738;
    --cardSeriousInjury:#FF9838;
    --cardSeriousIllness:#FF9838;
    --cardHiRiskIncident:#FF9838;
    --cardDeath:red;

    /** Misc values */
    /*--sliderWidth: 35px;*/
    --sliderWidth:100%;
}
/*
* {
    box-sizing: border-box;
    font-family: "robotoregular", "Helvetica Neue", sans-serif;
}
*/

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on June 21, 2023 */
@font-face {
    font-family: 'robotoblack';
    src: url('../../public/assets/fonts/roboto-black-webfont.woff2') format('woff2'),
        url('../../public/assets/fonts/roboto-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'robotoblack_italic';
    src: url('../../public/assets/fonts/roboto-blackitalic-webfont.woff2') format('woff2'),
        url('../../public/assets/fonts/roboto-blackitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'robotobold';
    src: url('../../public/assets/fonts/roboto-bold-webfont.woff2') format('woff2'),
        url('../../public/assets/fonts/roboto-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'robotobold_italic';
    src: url('../../public/assets/fonts/roboto-bolditalic-webfont.woff2') format('woff2'),
        url('../../public/assets/fonts/roboto-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'robotoitalic';
    src: url('../../public/assets/fonts/roboto-italic-webfont.woff2') format('woff2'),
        url('../../public/assets/fonts/roboto-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'robotolight';
    src: url('../../public/assets/fonts/roboto-light-webfont.woff2') format('woff2'),
        url('../../public/assets/fonts/roboto-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'robotolight_italic';
    src: url('../../public/assets/fonts/roboto-lightitalic-webfont.woff2') format('woff2'),
        url('../../public/assets/fonts/roboto-lightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'robotomedium';
    src: url('../../public/assets/fonts/roboto-medium-webfont.woff2') format('woff2'),
        url('../../public/assets/fonts/roboto-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'robotomedium_italic';
    src: url('../../public/assets/fonts/roboto-mediumitalic-webfont.woff2') format('woff2'),
        url('../../public/assets/fonts/roboto-mediumitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'robotoregular';
    src: url('../../public/assets/fonts/roboto-regular-webfont.woff2') format('woff2'),
        url('../../public/assets/fonts/roboto-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'robotothin';
    src: url('../../public/assets/fonts/roboto-thin-webfont.woff2') format('woff2'),
        url('../../public/assets/fonts/roboto-thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'robotothin_italic';
    src: url('../../public/assets/fonts/roboto-thinitalic-webfont.woff2') format('woff2'),
        url('../../public/assets/fonts/roboto-thinitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}