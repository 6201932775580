/** Ion toolbar element */
#nav-bar {
    --padding-top: 0;
    --padding-end: 0;
    --padding-bottom: 0;
    --padding-start: 0;
    --min-height: 20px;
    --background: var(--primaryBPContrast);
}
/** Tape div beneath ion toolbar  */
#divider-tape {
    height: 10px;
    width: 100%;
    background-color: var(--dangerBP);
    background-image: url(../assets/img/tape_transparent.svg);
    background-repeat: repeat-x;
    background-size: auto 100%;
}
/** Flex container for image and title if title is present */
#title-container {
    padding: 5px 0 5px 10px;
    height: 55px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
/** Hover style for OSS button as home button  */
#title-container a:hover {
    filter: brightness(1.1);
}
/** Container for stacked icon UI */
#icon-grid {
    display: grid;
    height: 45px;
    width: 45px;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(3, 1fr);
}
/** Blue background for stacked icon */
#icon-grid > div {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: var(--primaryBPTint);
}
/** img of stacked icon */
#icon-grid > div > img {
    height: 50%;
}
/** Icon layers */
.full-icon { grid-area: 1 / 1 / 4 / 4; }
.lower-icon { grid-area: 1 / 1 / 3 / 3; }
.upper-icon { grid-area: 2 / 2 / 4 / 4; }

/** Home page title img */
#oss-title-text {
    margin-left: 8px;
    height: 75%;
}
/** Page title */
#title-container h1 {
    margin: 0 0 0 8px;
    color: var(--logoGrey);
    font-size: 2.4rem;
    font-weight: bold;
}
/** Smaller font version for induction doc headings */
#title-container h2 {
    margin: 0 0 0 8px;
    color: var(--logoGrey);
    font-size: 1.8rem;
    font-weight: bold;
}
/** Menu icon */
#menu-button {
    margin-right: 10px;
    width: 35px;
    height: 25px;
    --padding-start: 0;
    --padding-end: 0;
    --border-radius: 0;
}
#lines-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
#lines-container div {
    flex-grow: 1;
}
#lines-container div:nth-child(odd) {
    width: 100%;
    background-color: var(--primaryBP);
}