/* Card element */
.incident-type-card {
    margin: 0;
    padding: 10px;
    border-radius: 10px;
}

/* Flex div inside card to centre items */
.incident-type-card div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 85px;
}

.incident-type-card.short div {
    height: auto;
}

/* Text items in card */
.incident-type-card span {
    text-align: center;
    color: white;
}
/* Card title */
.incident-type-card span:first-of-type {
    font-size: 1.8rem;
    font-weight: bold;
}
/* Card content */
.incident-type-card span:last-of-type {
    font-size: 1.4rem;
}