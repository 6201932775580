/** Ion menu component
We need the toolbar to push the top of the menu below the notch
- set height to 0 so it's not displayed
*/
#side-menu ion-toolbar {
    height: 0;
}
/** Ion list elements  */
.menu-list {
    padding: 8px;
}
/** First child has more spacing at top  */
.menu-list:nth-child(1) {padding-top: 16px;}
/** List titles  */
.menu-list h1 {
    margin: 0;
    font-size: 2.2rem;
}
/** List info (p) */
.menu-list p {
    font-size: 1.4rem;
}
.menu-list a {
    font-size: 1.4rem;
}
.menu-button {
    --background: var(--primaryBP);
    color: white;
}
.menu-button-help {
    --background: var(--dangerBP);
    color: white;
}