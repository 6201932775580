iframe {
    width:100%;
    height:100%;
}
/*
.react-pdf__Document
*/
.react-pdf__Page{
    overflow:scroll;
}

/* Navigation chevron */
.nav-chevron {
    font-size: 1.3rem;
}

/* Grid layout content div */
#documents-grid {
    display: grid;
    min-height: 100%;
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr;
}

.documents-nav {
    padding: 5px var(--pageMargin) var(--ion-safe-area-bottom, 5px) var(--pageMargin);
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 0.5fr 0.5fr 1fr;
    background-color: white;
    gap: 5px;
}
