.select-controls h4 {
    color: black;
    font-size: 1.2rem;
    font-weight: bold;
    align-self: center;
    margin-top: 0;
    padding-top:0;
    padding-bottom: 3px;
}

/* Hazard control items have a span instead of a label */
#hazard-control-item span {
    padding: 5px 0 5px 0;
}
/* Container for custom hazard sliding item */
.custom-hazard-container {
    border-bottom: 1px solid var(--lightGreyTA);
}
.custom-hazard-container span {
    margin-left: 10px;
}