/* All p elements on signature page */
#signature-page p {
    font-size: 1.2rem;
}
/* Italic p */
#signature-page > p {
    font-style: italic;
}
/* Buttons */
#signature-page ion-button {
    min-width: 80px;
}
.signature-name-container {
    display: flex; 
    flex-direction:column;    
}
.signature-lower-container {
    display: flex; 
    flex-direction:column;    
}
.signature-button-container {
    display: flex; 
    flex-direction:row; 
    align-items: center;
    justify-content: center;
}
.signature-pad {    
    margin: 0 auto 0 auto;
    width:90vw;
    height:45vw;
    border-style:solid;
    border-color: var(--primaryBPTint);
    border-width: 1px;
}
/* Canvas element */
.signature-pad canvas {
    touch-action: none;
}

@media (max-height: 650px) {
    .signature-name-container {
        flex-direction:row;  
        align-items: center;
        margin-bottom: 10px;  
    }
    .signature-name-container p { 
        margin: 0 0 0 10px;
    }
    .signature-lower-container {        
        flex-direction:row;    
    }
    .signature-button-container {     
        flex-direction:column;
        padding-left: 5px;        
    }
    .signature-pad {    
        width:60vw;
        height:30vw;
    }
}

/* Canvas container for drawing signature */




