/* List item for PPE */
#ppe-list {
    padding: 0;
}
/* Ion item */
#ppe-list ion-item {
    --padding-start: 0;
    margin: 5px 0 5px 0;
}
/* Grid container in ion item */
#ppe-list ion-item > div {
    display: grid;
    grid-template-rows: 40px;
    grid-template-columns: auto auto 1fr;
    gap: 10px;
}
#ppe-list ion-item > div ion-label {
    align-self: center;
}

.ppe-icon {
    width:2.2em;
    height: 2.5em;
    margin-left:5px;
    margin-right:10px;
    flex-grow: 0;
}

.ppe-item-selected {
    font-weight: bold;
}

h2#not-orange {
    color: black !important;
}