.risk-page h4 {
    color: black;
    font-size: 1.2rem;
    font-weight: bold;
    align-self: center;
    margin-top: 0;
    padding-top:0;
    padding-bottom: 3px;
}

.risk-page ul {
    padding-left: 20px;
    color: black;
    font-size: 1.2rem; 
}

/* Grid that displays hazard being evaluated */
.tree-grid-risk-page {
    margin: 10px 0 10px 0;
    display: grid;
    grid-template-columns: 20px 20px 20px auto 1fr;
    grid-template-rows: auto;
    gap: 0 5px;
}
/* Hazard title */
.tree-grid-risk-page > ion-label {
    color: black;
    font-size: 1.4rem;
    align-self: center;
}

/*! Styling of slider display */
.slider-display-container {
    margin-bottom: 20px;
    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-template-columns: 1fr;
    gap: 5px;
}
/* Orange text */
.slider-display-container > h2 {
    margin-bottom: 5px;
}
/* Risk slider */
.slider-display-container > div:first-of-type {
    /* margin: 0 10px 0 10px; */
}
/* Flex container with numbers */
.slider-display-container > div:last-of-type {
    display: flex;
    justify-content: space-around;
}
/* Numbers 1-5 */
.slider-display-container > div:last-of-type span {
    font-size: 1.4rem;
    font-weight: bold;
}

/*! Styling of risk display */
.risk-display {
    margin: 0 auto 0 auto;
    width: 80%;
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: auto auto;
    gap: 7px;
}
.risk-display h1 {
    font-weight: bold;
    grid-area: 1 / 1 / 2 / 3;
}
.risk-display div {
    grid-area: 2 / 1 / 3 / 2;
}
.risk-display p {
    margin: 0;
    grid-area: 2 / 2 / 3 / 3;
    font-size: 1.2rem;
}