/*
* {
    box-shadow: 0 0 5px turquoise;
}
*/

/* Applied to IonContent for pages that should have coloured background */
.signin-page {
    --background: var(--primaryBP);
}

ion-footer.signin-page {
    padding: 5px 0 var(--ion-safe-area-bottom, 5px) 0;
    background-color: var(--primaryBP);
}

/** Flex parent for QR screen  */
#qr-content-flex {
    display: flex;
    height: calc(100vh - 65px);
    flex-direction: column;
}

/* #################### */
/* # QR code scanning # */
/* #################### */
/* ! QR stuff start */
/** Flex container for QR scanner  */
#qr-container {
    width: 100%;
    max-height: 58vh;
    flex: 1;
    position: relative;
    display: flex;
    justify-content: center;
    background-color: black;
    overflow: hidden;
}

/** QR camera overlay  */
#qr-overlay {
    display: flex;
    padding: 4vh 0 3vh 0;
    width: auto;
    height: 100%;
    position: absolute;
    z-index: 5;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* box-shadow: 0 0 10px aqua; */
}

/* ? QR guide moved to separate file */
/** QR on screen buttons */
#qr-buttons {
    display: flex;
    justify-content: space-between;
}

#qr-buttons ion-button {
    margin: 0 10px 0 10px;
}


/** No camera error container  */
#no-cam-error {
    max-height: 58vh;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
}

#no-cam-error h1 {
    color: white;
    font-size: 2.8rem;
}

#no-cam-error ion-button {
    font-size: 1.6rem;
}

/* ! QR stuff end */

/** Divider tape between QR stuff and title box */
.divider-tape {
    flex: none;
}

/** Scan hint plus login to app box  */
#title-button-box {
    margin-top: 2vh;
    width: 100%;
    /* height: 200px; */
    /* height: calc(100vh - 75px - 60vh); */
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/** Scan hint  */
#title-button-box h2 {
    margin: 0;
    font-size: 2.4rem;
    font-weight: bold;
    color: white;
}

#title-button-box span {
    margin: 15px 0 15px 0;
    font-size: 2rem;
    color: white;
}

/** Log into app button  */
.qr-page-button {
    font-size: 2rem;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    height: auto;
    --border-radius: 15px;
    --background: var(--primaryBPTint);
    /* --background: rgb(17,106,164); */
    /* --background: linear-gradient(180deg, rgba(17,106,164,1) 0%, rgba(24,138,209,1) 50%, rgba(32,171,255,1) 100%); */

}

.qr-page-button.secondary {
    --background: var(--dangerBP)
}

/** Flex container cos buttons don't flex */
.qr-page-button div {
    padding: 0px;
    width: auto;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/** Cone button img */
.qr-page-button div img {
    margin-right: 7px;
    width: auto;
    height: 100%;
}

/** Button text  */
.qr-page-button div p {
    margin: 0;
    font-size: 2rem;
}

/* ################### */
/* # Site code entry # */
/* ################### */
/** Flex div containing vertically stacked elements */
#site-code-container {
    padding: 40px 5px 0 5px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

/** Enter site code info */
#site-code-container h1 {
    margin: 0;
    font-size: 2.4rem;
    color: white;
}

/** Positioned parent */
#all-inputs {
    margin: 40px 0 40px 0;
    position: relative;
}

/** Return to camera button */
#all-inputs+ion-button {
    --background: var(--primaryBPTint);
}

/** Grid containing inputs  */
#inputs-container {
    width: 300px;
    height: 60px;
    position: relative;
    display: grid;
    grid-template: 1fr / repeat(6, 1fr);
    gap: 5px;
}

/** Decorative inputs */
#inputs-container ion-input {
    --padding-top: 0;
    --padding-right: 0;
    --padding-bottom: 0;
    --padding-left: 0;
    --padding-start: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 3rem;
    background-color: white;
    border-style: solid;
    border-color: var(--primaryBPTint);
    border-radius: 8px;
}

/** Input from user */
#main-input {
    --padding-top: 0;
    --padding-right: 0;
    --padding-bottom: 0;
    --padding-left: 0;
    --padding-start: 14px;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    font-size: 3rem;
    letter-spacing: 34px;
    color: rgba(0, 0, 0, 0);
    caret-color: black;

}

/* #################### */
/* # Get user details # */
/* #################### */
/* Log in button */
#user-details-page ion-button:first-of-type {
    margin-top: 10px;
}

/* Min width of all buttons */
#user-details-page ion-button {
    min-width: 150px;
}

/* "or" text in divider */
.divider-p {
    padding: 0 5px 0 5px;
    color: var(--ossPlaceholderColor);
}

/* Guest details form */
#user-details-page form {
    display: flex;
    flex-direction: column;
    gap: 7px;
}


/* ################ */
/* # Display docs # */
/* ################ */
#signin-doc-container {
    display: grid;
    min-height: 100%;
}

.present-doc-nav {
    padding: 5px var(--pageMargin) var(--ion-safe-area-bottom, 5px) var(--pageMargin);
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    background-color: white;
    gap: 5px;
}

.present-paged-doc-nav {
    padding: 5px var(--pageMargin) var(--ion-safe-area-bottom, 5px) var(--pageMargin);
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 0.3fr 0.3fr 1fr 1fr;
    background-color: white;
    gap: 5px;
}