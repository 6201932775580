.tasks-list-temp {
    margin-left: 25px;
}
.full-width-temp {
    width: 100%;
}
.right-button-temp {
    color: white;
    margin-left: auto;
}

.checkbox-label {
    font-size: 16px;
}