/* Gap between sliding items in list */
#discussion-items-list ion-item-sliding:first-of-type {
    margin: 0 0 20px 0;
}
#discussion-items-list ion-item-sliding {
    margin: 20px 0 20px 0;
    border-top: 2px solid lightgray;
    border-bottom: 2px solid lightgray;
}
/* div inside sliding item for item content */
.discussion-item {
    display: flex;
    flex-direction: column;
    /* gap: 15px; */
    width: 100%;
}
/* Discussion item ion-textarea */
.discussion-item > div:first-of-type {
    margin-bottom: 5px;
}
.discussion-item > div:first-of-type > ion-textarea {
    font-size: 1.8rem;
    font-weight: bold;
}

.discussion-item .swiper {
    padding: 0 10px 0 10px !important;
    background-color: var(--inputBackground);
}
.discussion-item > div:last-of-type {
    /* display: none; */
    /* grid-area: 3 / 1 / 4 / 3; */
}