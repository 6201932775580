/* Flex container for page */
#general-details-form {
    margin: var(--pageMargin);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 18px;
}
/* Full width for items on page */
#general-details-form * {
    width: 100%;
    /* box-shadow: 0 0 2px palevioletred; */
}

/* Select list input container */
.select-list-input-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

/* Input component for task name */
#task-name {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
/* Task Name label */
#task-name .oss-input-label {
    margin-bottom: 5px;
}

/* Text area component */
#task-description .oss-input {
    margin-top: 0;
}

/* Ion-list component for supervisor/worker details */
#people-list {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}
/* Removing default settings ion items */
#people-list ion-item {
    --padding-start: 0;
    --inner-padding-end: 0;
    --min-height: unset;
}
/* Div within ion item used for grid */
#people-list ion-item > div {
    display: grid;
    grid-template-columns: 1.2fr 1fr 25px;
    grid-template-rows: auto;
    gap: 5px;
}
/* Styling of text in grid */
#people-list ion-item > div p {
    padding: 10px 0 10px 5px;
    font-size: 1.4rem;
    color: black;
}
/* Separator between supervisor and worker sections */
#people-list ion-item-divider {
    margin-bottom: 8px;
    --color: var(--ossPlaceholderColor);
}

/* Container for ion-items containing supervisor/worker info */
.people-details {
    height: auto;
    display: grid;
    grid-template-columns: 1.2fr 1fr 25px;
    grid-template-rows: auto auto;
    gap: 0 5px;
    /* border-bottom: 1px solid var(--ossPlaceholderColor); */
}
/* Grid sizing for ion item/sliding ion item */
.people-details ion-item, .people-details ion-item-sliding {
    grid-column: 1 / 4;
    --background: var(--inputBackground);
}
/* Padding for text */
.people-details > ion-item, .people-details ion-item-sliding {
    margin: 5px 0 5px 0;
}
/* Italic headers for role and phone number */
.people-details span {
    padding-left: 3px;
    font-size: 1.4rem;
    font-style: italic;
    color: var(--ossPlaceholderColor);
}
/* Person name and phone number */
.people-details p {
    margin: 0;
    padding: 3px 0 3px 3px;
    height: auto;
    font-size: 1.8rem;
    color: var(--ossLabelColor);
}
/* Opening chevron container */
.opening-chevron-container {
    background-color: white;
}
/* Opening chevron icon */
.opening-chevron-container ion-icon {
    color: var(--primaryBPTint);
}
/* Closing chevron container */
.closing-chevron-container {
    width: 25px !important;
    background-color: #f4f5f9;
}

/* Button to add worker */
#add-worker-btn {
    margin: 5px 0 0 0;
    width: auto;
    font-size: 1.8rem;
    text-transform: none;
    --background: var(--primaryBP);
}

/* Footer */
/*! NOTE: First and last page only */
#TA-first-last-page-footer {
    padding: 5px var(--pageMargin) 20px var(--pageMargin);
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    background-color: white;
    gap: 5px;
}
#TA-first-last-page-footer ion-button {
    height: 27px;
    font-size: 1.4rem;
    text-transform: none;
}
#TA-first-last-page-footer ion-button:nth-of-type(1) {
    --background: var(--darkGreyTA);
}
#TA-first-last-page-footer ion-button:nth-of-type(2) {
    --color: black;
    --background: var(--lightGreyTA);
    /* --background: var(--successBP); */
}
#TA-first-last-page-footer ion-button:nth-of-type(3) {
    --background: var(--primaryBP);
}