/* SWMS heading container */
.ta-task-heading {
    padding-bottom: 10px;
}

/* Styling of all text in title */
.ta-task-heading span {
    font-size: 2rem;
}

/* Styling specific to task title */
.ta-task-heading .task-title {
    text-transform: uppercase;
    font-weight: 600;
}

/* Styling specific to step number */
.ta-task-heading .task-step {
    white-space: nowrap;
}