/* div container for slider */
.slider-v3-container {
    margin: 0 auto 0 auto;
    width: 90%;
    height: 40px;
}

/*! Colors?? */
/* Default */
.slider-v3 {
    --sliderColor: grey;
}

.slider-v3.red {
    --sliderColor: var(--ion-color-danger);
}

.slider-v3.green {
    --sliderColor: var(--ion-color-success);
}


/* ######################### */
/* # Hiding default slider # */
/* ######################### */
.slider-v3 {
    -webkit-appearance: none;
    width: 100%;
    height: 40px;
    background: transparent;
    border-radius: 8px;
    overflow: hidden;
}

.slider-v3::-webkit-slider-thumb {
    -webkit-appearance: none;
}

.slider-v3:focus {
    outline: none;
}

.slider-v3::-ms-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
}

/* ######################## */
/* # Styling slider thumb # */
/* ######################## */
/* Special styling for WebKit/Blink */
.slider-v3::-webkit-slider-thumb {
    -webkit-appearance: none;
    margin-top: 0;
    height: 40px;
    width: 40px;
    background: var(--sliderColor);
    border-radius: 8px;
    box-shadow: -100vw 0 0 calc(100vw - 8px) var(--sliderColor);
    cursor: pointer;
}

/* All the same stuff for Firefox */
.slider-v3::-moz-range-thumb {
    height: 40px;
    width: 40px;
    background: var(--sliderColor);
    border-radius: 8px;
    border: none;
    box-shadow: -100vw 0 0 calc(100vw - 8px) var(--sliderColor);
    cursor: pointer;
}

/* ################# */
/* # Styling track # */
/* ################# */
.slider-v3::-webkit-slider-runnable-track {
    width: 100%;
    height: 40px;
    background: var(--lightGreyTA);
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
}

.slider-v3:focus::-webkit-slider-runnable-track {
    background: var(--lightGreyTA);
}

.slider-v3::-moz-range-track {
    width: 100%;
    height: 40px;
    background: var(--lightGreyTA);
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
}

.slider-description {
    position: fixed;
    z-index: 10;
    top: calc(var(--ion-safe-area-top) + var(--pageMargin) + 65px);
    left: var(--pageMargin);
    right: var(--pageMargin);
    padding: 15px;
    height: 100px;
    border-color: var(--dangerBP);
    background-color: white;
    border-style: solid;
    border-width: 2px;
    border-radius: 8px;
}