#worksafe-info-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 100%;
}
/* Generic p styling */
#worksafe-info-container p {
    font-size: 1.4rem;
    margin: 5px 0 0 0;
}
/* Generic h3 elements */
#worksafe-info-container h3 {
    color: var(--matrixHigh);
}
/* Title */
#worksafe-info-container h1 {
    font-size: 2.4rem;
    font-weight: 600;
}
/* Subtitle */
#worksafe-info-container > p:nth-of-type(1) {
    margin: 0;
    font-size: 1.6rem;
}

/** Please note container */
#info-note-container h2 {
    font-weight: normal;
}
#info-note-container strong:first-of-type {
    color: var(--matrixExtreme);
}

/* Death */
#worksafe-info-container > div:nth-of-type(5) h3 {
    color: var(--matrixExtreme);
}

#worksafe-logo {
    margin-top: auto;
}
#worksafe-logo h1 {
    font-size: 3rem;
    font-weight: 800;
}