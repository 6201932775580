/** Ion column component */
.card-column {
    padding: 0;
}
/** Ion card component  */
.activity-card {
    margin: 5px;
    height: auto;
    color: white;
    background-color: var(--primaryBPTint);
    border-radius: 9px;
}
/** Flex container for content because ion card is wacky and doesn't behave  */
.flex-card {
    padding: 2px 5px 2px 5px;
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
/** Divs for filling remaining area */
.flex-card div {
    width: 100%;
    flex: 1 0;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: aquamarine; */
}
/** Card icon  */
.flex-card img {
    height: 35px;
}
/** Card title */
.flex-card h1 {
    margin: 0;
    font-size: 2.1rem;
    font-weight: bold;
}
/** Card text */
.flex-card p {
    margin: 0;
    font-size: 1.4rem;
    color: var(--secondaryBPTint);
}