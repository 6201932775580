.html-doc-container {
    font-family: "Roboto", "Helvetica Neue", sans-serif;
    font-size: 1.4rem;
    color: black;
    padding:0;
    height:100%;
}

.html-doc-container input {
    margin:5px;
}

.html-doc-container input[type=checkbox] {
    margin:5px;
}

.html-doc-container .flex-row {
    width:100%;
    padding:5px;
    min-height:2em;
    display: flex; 
    flex-direction: row;
    align-items: center;
}

.html-doc-container .flex-column {
    padding:5px;
    display: flex; 
    flex-direction:column;
}
