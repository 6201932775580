.toolbox-nav {
    padding: 5px var(--pageMargin) var(--ion-safe-area-bottom, 5px) var(--pageMargin);
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    background-color: white;
    gap: 5px;
}

.toolbox-nav ion-button:last-of-type {
    grid-column-start: 3;
}