.accept-terms-nav {
    padding: 5px var(--pageMargin) var(--ion-safe-area-bottom, 5px) var(--pageMargin);
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    background-color: white;
    gap: 5px;
}

.accept-terms-paged-nav {
    padding: 5px var(--pageMargin) var(--ion-safe-area-bottom, 5px) var(--pageMargin);
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 0.5fr 0.5fr 1fr 1fr;
    background-color: white;
    gap: 5px;
}