/* Div wrapper for clickable icon and text */
.camera-input-container {
    display: flex;
    align-items: center;
    gap: 5px;
    color: var(--ossPlaceholderColor);
    font-size: 1.8rem;
}
/* Sizing of ion icon */
.camera-input-container > ion-icon {
    width: 40px;
    height: 40px;
}
/* Height fudge of text */
.camera-input-container > span {
    padding-top: 5px;
}
/* Ion label alignment fudge */
.camera-input-container > ion-label {
    align-self: center !important;
}