#review-popover-fullscreen {
    position: absolute;
    bottom: calc(var(--ion-safe-area-bottom) + 49px);
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

#review-popover-fullscreen>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: auto;
    padding: 10px;
    width: 230px;
    background-color: white;
    border-radius: 5px 5px 0 0;
    /* box-shadow: 0 0 10px grey; */
}