/** Ion toolbar element */
#alt-nav-bar {
    --padding-top: 0;
    --padding-end: 0;
    --padding-bottom: 0;
    --padding-start: 0;
    --min-height: 55px;
    --background: var(--primaryBP);
}
/** Page title */
#alt-nav-bar h1 {
    margin: 0 0 0 8px;
    color: white;
    font-size: 2rem;
    font-weight: bold;
}
/** Tape div beneath ion toolbar  */
.divider-tape {
    height: 10px;
    width: 100%;
    background-color: var(--dangerBP);
    background-image: url(../assets/img/tape_transparent.svg);
    background-repeat: repeat-x;
    background-size: auto 100%;
}
#alt-nav-bar ion-icon {
    color: white;
    transform: scale(1.5);
}