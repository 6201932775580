/* Title of step between divider lines */
#step-name {
    margin: 7px 0 7px 0;
}

/* Orange hazard question */
#hazard-question {
    margin: 10px 0 0 0;
    font-size: 1.7rem;
    color: var(--dangerBP);
}

/*! Preset hazards (mostly?) */
/* ion list element for hazard categories */
#preset-hazards-list, #custom-hazards-list {
    padding: 0;
}
/* ion item in list */
#preset-hazards-list ion-item {
    --padding-start: 0;
}
/* Grid container in ion item */
#preset-hazards-list ion-item > div {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: auto auto 1fr;
    gap: 10px;
}

/*! Custom hazards */
#custom-hazards-list ion-label {
    margin: 0 0 0 10px;
}

/*! Generic to both lists  */
/* Checkbox */
.centered-checkbox {align-self: center;}
/* .hazard-list ion-checkbox {
    --background: var(--primaryBPTint);
    --background-checked: var(--dangerBP);
} */
/* Label text */
.hazard-list ion-label {
    font-weight: bold;
}

/* Form element for adding new hazards */
#custom-hazard-entry {
    margin-top: 10px;
}
/* h2 heading */
#custom-hazard-entry h2 {
    color: var(--dangerBP);
    font-size: 1.7rem;
}
/* Grid container */
#custom-hazard-entry > div {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 3fr 1fr;
    gap: 10px;
    align-items: center;
}
/* ADD Button */
#custom-hazard-entry ion-button {
    padding-top: 4px;
    height: 42px;
}