/** Ion content element */
#splash-content {
    --background: var(--primaryBP);
}
/** Flex container for positioning items  */
#splash-container {
    padding: 12vh 7px 4vh 7px;
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
/** Cone logo */
#splash-container > :nth-child(1) {
    height: 15vh;
}
/** Tape divider and app title  */
#tape-title {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
}
/** Caution tape div */
#tape-title div {
    margin: 6vh 0 2vh 0;
    height: 2vh;
    width: 100%;
    background-color: var(--dangerBP);
    background-image: url(../assets/img/tape_transparent.svg);
    background-repeat: repeat-x;
    background-size: auto 100%;
}
/** OSS logo */
#tape-title img {
    height: 8vh;
}
/** Builda price branding grid container */
#bp-logo-grid {
    display: flex;
    justify-content: center;
    margin-top: auto;
}
/* Single SVG for text and icons */
#bp-logo-grid img {
    height: auto;
    width: 75%;
}

.login-text {
    flex-grow: 2;
    text-align: center;
    margin: 0;
    font-size: 2rem;
    color: white;
}