/* Image viewer container */
.img-viewer-wrapper {
    position: relative;
    margin: 15px 0 15px 0;
}
.img-viewer-wrapper > div:first-of-type {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    transform: translate(40%, -50%);
    width: 20px;
    height: 20px;
    font-size: 14.4px !important;
    color: white;
    background-color: var(--ion-color-danger);
    border-radius: 50%;
}