/* ######################### */
/* # Generic global styles # */
/* ######################### */

/** Whitespace around content */
.content-padding {
    padding: var(--pageMargin);
    --padding-start: var(--pageMargin);
    --padding-end: var(--pageMargin);
    --padding-top: var(--pageMargin);
    --padding-bottom: var(--pageMargin);
}

.p-1 {
    padding: 5px
}

/** Flex properties  */
.flex {
    display: flex;
}

.row {
    align-items: center;
}

.centre-row {
    justify-content: center;
    align-items: center;
}

.column {
    flex-direction: column;
}

.centre-column {
    flex-direction: column;
    align-items: center;
}

/** Ion grid wrappers */
.app-grid {
    --ion-grid-padding: 0;
    --ion-grid-column-padding: 0;
}

ion-item {
    --padding-start: 0 !important;
}

/* ############## */
/* # OSS styles # */
/* ############## */

/** OSS Input boxes */
.oss-input {
    --padding-start: 5px !important;
    --padding-top: 10px;
    --padding-bottom: 10px;
    min-height: 25px !important;
    background-color: var(--inputBackground) !important;
    font-size: 1.4rem;
    --placeholder-color: var(--placeholder-color);
}

ion-textarea {
    margin: 0 !important;
    padding-left: 5px;
}

.oss-input-label {
    font-size: 1.6rem;
    color: var(--ossLabelColor);
}

/** Error messages */
.oss-input-error {
    border-color: var(--dangerBP);
}

.oss-input-error-message {
    color: var(--dangerBP);
    font-size: 1.5rem;
}

/** Default button */
ion-button {
    /* min-width: 100px; */
    text-transform: none;
    --background: var(--primaryBP);
}

/** Default checkbox */
ion-checkbox {
    margin: 0;
    --border-style: none;
    --border-radius: 4px;
    --background: lightgrey;
    --background-checked: var(--primaryBPTint);
}

.cb-green {
    --background: var(--ion-color-success);
    --background-checked: var(--ion-color-success);
    --border-color: var(--ion-color-success);
    --border-color-checked: var(--ion-color-success);
}

.cb-grey {
    --background: lightgrey;
    --background-checked: grey;
    --border-color: grey;
    --border-color-checked: grey;
}

/* Un-greying greyed out checkboxes */
ion-checkbox.checkbox-disabled.disabled-cb {
    opacity: 1;
}

/** Non-bubbling html checkbox */
.checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    background-color: transparent;
}

.checkbox-img-container {
    display: block;
    /* position: relative; */
    padding: 5px 10px 5px 0;
    width: calc(18px + 2 * 5px);
    height: calc(18px + 2 * 5px);
    flex-shrink: 0;
}

.checkbox-label {
    color: black;
    height: fit-content;
    text-align: left;
}

.checkbox-container.disabled>.checkbox-img-container {
    opacity: 0.3;
}

.checkbox-container.disabled>.checkbox-label {
    opacity: 0.3;
}

/* Hiding default input element */
/* .checkbox-container > input {
    position: absolute;
    width: 0 !important;
    height: 0 !important;
    opacity: 0;
} */
/* Styled span element acts as unchecked box */
/* .checkbox-container > span {
    position: absolute;
    top: 5px;
    right: 5px;
    bottom: 5px;
    left: 5px;
    background-color: lightgrey;
    border-radius: 4px;
} */
/* Checked input adds background image to span */
/* .checkbox-container > input:checked ~ span {
    background-image: url(../src/assets/img/checkbox_checked_blue.svg);
} */
/** Fake img based checkboxes */
.fake-checkbox {
    display: block;
    /* margin: 5px; */
    width: 18px;
    height: 18px;
    background-color: lightgrey;
    border-radius: 4px;
}

/* Experimental - for div added below an input so it's not covered by keyboard */
.keyboard-space {
    height: 350px
}

/** Default divider? */
ion-item-divider {
    min-height: 2px;
}

/** Default ion-refresher */
ion-refresher {
    padding: 5px 0 100px 0;
    height: auto;
    background-color: white;
}

/* ######################## */
/* # Default text styles  # */
/* ######################## */

h1 {
    margin: 0;
    font-size: 2rem;
}

h2 {
    margin: 0;
    font-size: 1.7rem;
}

.bp-orange {
    color: var(--dangerBP);
}

h3 {
    margin: 0;
    font-size: 1.6rem;
    font-weight: bold;
}

p {
    font-size: 1.2rem;
}

.bold-caps {
    text-transform: uppercase;
    font-weight: 600;
}

ion-label {
    color: var(--ossLabelColor);
}





/* textarea {
    margin-top: 0;
    width:100%;
    height:fit-content;
} */

/* ion-item-divider {
    min-height:2px;
} */

/* .oss-input {
    padding:2px;
    color:black;
    background-color: var(--inputBackground);
    font-size: 1.5rem;
} */

div.oss-input {
    /* padding-left:5px; */

    /* Just to centre contents vertically */
    /* display: flex;  */
    /* flex-direction: row; */
    /* align-items: center;     */
}

/* If label / input pair used inside a flex-row, keep the label 
a fixed size and let the input expand to fill remaining space */
.flex-row .oss-input-label {
    /* flex-grow:0 */
}

.flex-row .oss-input {
    /* flex-grow:1 */
}

/* ion-label.oss-input-label {
    padding:5px;
    margin:2px;
    font-size: 1.8rem;
} */



/* .normal-page h1 {     */
/* padding:5px; */
/* margin:0; */
/* font-size: 2rem; */
/* } */

.normal-page h2 {
    /* padding:5px; */
    /* margin:0; */
    /* font-size: 1.8rem; */
}

.normal-page h3 {
    /* padding:5px; */
    /* margin:0; */
    /* font-size: 1.5rem; */
    /* font-weight: bold; */
}

.normal-page p {
    /* padding:5px;
    margin:2px;
    font-size:1.5rem;     */
}

/*
.normal-page div {    
    font-size:1.5rem
}
*/
/* .normal-page ul {
    padding:0;    
    margin-top:0;
    margin-bottom:0;   
    margin-left:5px;
} */

/* .normal-page li {    
    color:black;
    padding:5px;
    margin:2px;
    font-size:1.5rem
} */

.cat-select-level {
    padding: 5px;
    min-height: 2em;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.cat-select-level>div {
    margin-left: 5px;
}

.cat-select-level.item {
    font-size: 1.7rem;
    font-weight: bold;
}

.item-icon {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
}

.flex-row {
    /* padding:5px; */
    min-height: 2em;
    display: flex;
    flex-direction: row;
    /*  flex-wrap:wrap;*/
    align-items: center;
}

.flex-row.space-between {
    justify-content: space-between;
}

.flex-column {
    /* padding:5px; */
    display: flex;
    flex-direction: column;
}

/* IonIcon checkbox used for non-clickable checkboxes */
/* ion-icon.checkbox {
    width:23px;
    height:23px;
    border-radius: 4px;
} */

ion-icon.cb-grey {
    color: grey;
}


.table-heading {
    padding: 5px;
    padding-left: 8px;
    font-size: 1.2rem;
    font-weight: bold;
}

.table-row {
    padding: 5px;
    padding-left: 8px;
    font-size: 1.2rem;
}

/* Trying this to fix safari line break problem... (didn't work...)*/
textarea {
    white-space: pre-wrap;
}

iframe {
    border: none;
}

.safe-area-top {
    margin-top: var(--ion-safe-area-top)
}

.safe-area-bottom {
    margin-bottom: var(--ion-safe-area-bottom)
}

ion-icon.location {
    color:green
}
.icon-space {
    width:24px;
    height: 24px;
}

/* #################### */
/* # Temporary styles # */
/* #################### */