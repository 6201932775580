.radio-group-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0 10px;
}
.radio-group-container ion-item {
    --padding-start: 5px;
    --padding-end: 0;
    --min-height: 20px;
}
.radio-label-wrapper {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 1.4rem;
}