/* CSS grid container for buttons */
#incident-type-grid {
    display: grid;
    grid-template-columns: 1fr 2px 1fr;
    gap: 15px;
    margin-top: 10px;
    height: auto;
}

/* Column start for reported and unreported items */
.unreported {grid-column-start: 1;}
.reported {grid-column-start: 3;}

/* Vertical line separating reported and unreported types */
#vertical-divider {
    grid-area: 1 / 2 / 6 / 3;
    background-color: var(--lightGreyTA);
}

/* Spans explaining incident types */
.explanation {
    font-size: 1.2rem;
}

/* Special full width card to return to toolbox talk */
#incident-type-grid ion-card.full-width-card {
    text-align: center;
    grid-column: 1 / 4;
}