/* Container for each item in the step hazards list */
#step-hazards-list > div {
    padding: 10px 0 10px 0;
    border-bottom: 1px solid var(--lightGreyTA);
}

/* Step number and title container */
#step-hazards-list > div > div {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: auto auto 1fr;
    gap: 5px;
}

#step-hazards-list ion-button {
    min-width: 250px;
    margin: 5px 0 5px 0;
}