/* ######### */
/* # Start # */
/* ######### */
/* Nothing yet */


/* ########### */
/* # Details # */
/* ########### */
/* HTML form element for observation details */
#details-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
/* Adding spacing to divider after form */
#details-form ~ ion-item-divider {
    margin-bottom: 15px;
}


/* ########### */
/* # Actions # */
/* ########### */
/* Random dividers idk */
.observation-divider {
    margin: 10px 0 10px 0;
}
/* HTML form element for actions taken */
#mitigation-form {
    display: flex;
    flex-direction: column;
    /* gap: 15px; */
}
/* ion-list element of actions */
#mitigation-list {
    padding: 0;
}
/* ion-item that holds fields */
#mitigation-list > ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --inner-padding-end: 0;
}
/* Flex element that controls ion-item contents */
#mitigation-list > ion-item > .flex.column {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
/* Adding margin to camera input component */
#mitigation-form .camera-input-container {
    margin: 10px 0 10px 0;
}
/* Closeout spans */
.closeout-details span {
    font-size: 1.4rem;
}