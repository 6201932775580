/** Ion content element */
#home-content {
    --background: var(--primaryBP);
}
/** Ion grid element */
#activities-grid {
    --ion-grid-width-lg: 900px;
    --ion-grid-width-xl: 900px;
}
/** Full width column on home screen */
.full-width-card {
    padding: 0;
}
/** Ion card element nested in column */
.full-width-card > ion-card {
    margin: 5px;
    height: auto;
    color: white;
    /*background-color: var(--dangerBP);*/
    border-radius: 9px;
}
/** Flex box div as ion card doesn't do flex properly */
.full-width-card .container {
    padding: 10px;
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
/** Card icon */
.full-width-card img {
    flex-grow: 1;
    height: 40px;
}

.full-width-card ion-icon {
    flex-grow: 1;
    height: 40px;
    color:white;
}

.sign-in-card {
    background-color: var(--dangerBP);
}
.sign-out-card {
    background-color: var(--successBP);
}

/** Container for stacked text */
.scan-in-text {
    flex-grow: 2;
    text-align: center;
}
/** Full-width card title */
.scan-in-text h1 {
    margin: 0;
    font-size: 2.1rem;
    font-weight: bold;
}
/** Full-width card text */
.scan-in-text p {
    margin: 0;
    font-size: 1.4rem;
    color: var(--secondaryBPTint);
}
@media (min-width:992px) {
    .full-width-card .container {
        height: 120px;
    }
}