/* Bold h3 number heading of item */
.sliding-item h3 {
    margin-right: 5px;
}

/* Expand Button */
.sliding-item .open-button {
    margin-left: auto;
    --padding-start: 0;
    --padding-end: 0;
    width: 25px;
    flex-shrink:0;
    color: var(--primaryBPTint);
    --background: none;
}


.item-row-with-menu {
    display:flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
    width:100%;
    /* padding-left: 16px; */
    --padding-start: 0;
    --padding-end: 0;
    --inner-padding-end: 0;
}

.item-row-with-menu .flex {
    flex:1;
}

/* item-sliding-active-slide class is present when slider is slid */
ion-item-sliding.item-sliding-active-slide .open-button {
    opacity: 0;
}

/* Ion item options element */
.options-container {
    padding: 5.5px 0 5.5px 0;
}