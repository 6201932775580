/* Change order container */
.reorder-toggle-container {
    /* padding-left: var(--padding-start); */
}
/* Reorder label */
.reorder-toggle-container ion-label {
    font-size: 1.2rem;
}
/* Reorder toggle */
.reorder-toggle-container ion-toggle {
    padding: 10px;
    --background-checked: #99DBFF;
    --handle-background-checked: var(--primaryBPTint);
}

/* Ion list element */
#task-step-list {
    margin-bottom: 5px;
    padding: 0;
}

/* Input element wrapper */
.step-item-container > div {
    width: 100%;
}

/* div within ion item in reorder list */
.reorder-row {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: auto auto auto;
    gap: 5px;
}