/* ion-list container */
#registrar-list {
    padding: 0;
}
/* Grid layout for items in table */
.register-table {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 15vw 1fr 25px 25px;
    grid-template-rows: auto auto;
    gap: 0 5px;    
}
/* no more padding ion-item */
#registrar-list ion-item {
    margin: 5px 0 5px 0;
    --padding-start: 0;
    --padding-end: 0;
    --inner-padding-end: 0;
}
/* ion icons */
#registrar-list ion-icon {
    width: 100%;
}