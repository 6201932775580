/* CSS grid container for buttons */
#incident-start-grid {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    margin-top: 10px;
    height: auto;
}
/* Card element for each item in grid */
#incident-start-grid ion-card {
    margin: 0;
    padding: 10px;
    border-radius: 10px;
}
/* Special full width card to return to toolbox talk */
#incident-start-grid ion-card.full-width-card {
    text-align: center;
    grid-column: 1 / 3;
}
/* Flex dix inside card to centre items */
#incident-start-grid ion-card div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90px;
}
/* Coloured backgrounds */
#incident-start-grid ion-card:nth-child(1) {
    --background: var(--primaryBP);
}
#incident-start-grid ion-card:nth-child(2) {
    --background: grey;
}
#incident-start-grid ion-card:nth-child(3) {
    --background: #FF9838;
}
#tincident-start-grid ion-card:nth-child(4) {
    --background: #00A859;
}
/* Text items in card */
#incident-start-grid ion-card span {
    font-size: 1.8rem;
    font-weight: bold;
    color: white;
}