.hazard-label {
    font-size: 1.4rem;
    color: black;
}


/* ############### */
/* # Grid layout # */
/* ############### */

.tree-grid {
    margin: 10px 0 5px 0;
    display: grid;
    grid-template-columns: 20px auto auto auto 1fr;
    grid-template-rows: auto;
    gap: 10px 5px;
}

.col-1-arrow {
    grid-column-start: 1;
    justify-self: center;
    align-self: center;
}

.col-1-cb {
    grid-column-start: 1;
    justify-self: center;
    align-self: center;
}

.col-1-item {
    grid-column-start: 1;
    grid-column-end: end;
}

.col-2-cb {
    grid-column-start: 2;
    justify-self: start;
    align-self: center;
}

.col-2-item {
    grid-column-start: 2;
    grid-column-end: end;
}

.col-3-cb {
    grid-column-start: 3;
    justify-self: center;
    align-self: center;
}

.col-3-item {
    grid-column-start: 3;
    grid-column-end: end;
}

.col-4-cb {
    grid-column-start: 4;
    justify-self: center;
    align-self: center;
}

.col-4-item {
    grid-column-start: 4;
    grid-column-end: end;
}