/* ######### */
/* # Start # */
/* ######### */
/* Nothing yet */


/* ############ */
/* # Details1 # */
/* ############ */
/* HTML form element for incident details */
#incident-details-form {
    display: flex;
    flex-direction: column;
    gap: 10px;

    /* Large bottom margin so page can scroll up when keyboard displayed
    To be tested... */
    margin-bottom: 50vh;
}

/* Grid layout for date and time selector */
#date-time-container {
    display: grid;
    grid-template-rows: 1.7rem 3.8rem;
    grid-template-columns: 1fr 30px auto 1fr;
}
/* Span label elements */
#date-time-container > span {
    font-size: 1.4rem;
    color: var(--ossLabelColor);
}

/* Date selector input */
#date-time-container div:nth-of-type(1) {
    grid-area: 2 / 1 / 3 / 2;
    background-color: var(--inputBackground);
}

/* Label for time selector */
#date-time-container span:last-of-type {
    grid-area: 1 / 3 / 2 / 4;
}
/* Time selector icon */
#date-time-container div:nth-of-type(2) {
    display: flex;
    align-items: center;
    grid-area: 2 / 3 / 3 / 4;
    background-color: var(--inputBackground);
}
#date-time-container div:nth-of-type(2) > img {
    display: block;
    height: 25px;
}
/* Time selector input */
#date-time-container div:nth-of-type(3) {
    grid-area: 2 / 4 / 3 / 5;
    background-color: var(--inputBackground);
}

/* ################### */
/* # WorkersInvolved # */
/* ################### */
/* Overall list containing workers involved */
#incident-worker-list {
    padding: 0;
}
/* Targeting h3 to space out elements in list */
#incident-worker-list > h3 {
    margin: 10px 0 10px 0;
}
/* Last divider in options of specific worker */
#incident-worker-list .last-divider {
    margin-bottom: 10px;
}

/* ############ */
/* # Details2 # */
/* ############ */
/* Parent form element */
#details2-form {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
/* Divider between form items */
#details2-form ion-item-divider {
    margin: 5px 0 5px 0;
}
/* Interface with checkbox label to display nicely */
#details2-form .checkbox-wrapper {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 2px 0 0;
    font-size: 1.2rem;
}

/* ########### */
/* # Actions # */
/* ########### */
/* Parent list element for actions */
#incident-actions-list {
    padding: 0;
}
/* Text specifying action is implemented */
#incident-actions-list p {
    margin: 5px 0 5px 0;
    font-size: 1.2rem;
    color: var(--ossLabelColor);
}

/* Divider between individual sets of action items */
#incident-actions-list ion-item-divider {
    margin: 10px;
}