.date-select-container {
    --padding-start: var(--pageMargin);
    --padding-end: var(--pageMargin);
    --padding-top: var(--pageMargin);
    --padding-bottom: var(--pageMargin);
}

.date-select-container ion-button {
    display: block;
    margin: 10px auto 0 auto;
    width: 100px;
    --background: var(--primaryBP)
}