.TA-nav-footer {
    padding: 5px var(--pageMargin) var(--ion-safe-area-bottom, 5px) var(--pageMargin);
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    background-color: white;
    gap: 5px;
}

.TA-nav-footer ion-button {
    height: 27px;
    font-size: 1.4rem;
    text-transform: none;
}

.TA-nav-footer ion-button:nth-of-type(1) {
    --background: var(--darkGreyTA);
}

.TA-nav-footer ion-button:nth-of-type(2) {
    --color: black;
    --background: var(--lightGreyTA);
}

.TA-nav-footer ion-button:nth-of-type(3) {
    --background: var(--primaryBP);
}