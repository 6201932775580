:root {
    --guideSize: 40vh;
    --guideThickness: 1.5vh;
    --guideLength: 8vh;
}
#qr-guide {
    width: var(--guideSize);
    height: var(--guideSize);
    display: grid;
    grid-template:
        var(--guideThickness) var(--guideLength) 1fr var(--guideLength) var(--guideThickness) /
        var(--guideThickness) var(--guideLength) 1fr var(--guideLength) var(--guideThickness);
}
#qr-guide > div {
    background-color: black;
    border-color: white;
    border-width: 0.3vh;
}
#qr-guide > div.i {background-color: rgba(0, 0, 0, 0);}
.t {border-top-style: solid;}
.r {border-right-style: solid;}
.b {border-bottom-style: solid;}
.l {border-left-style: solid;}

/* Top left */
#tlh {grid-area: 1 / 2 / 2 / 3;}
#tlv {grid-area: 2 / 1 / 3 / 2;}
#tlc {grid-area: 1 / 1 / 2 / 2;}
#tli {grid-area: 2 / 2 / 3 / 3;}

/* Top right */
#trh {grid-area: 1 / 4 / 2 / 5;}
#trv {grid-area: 2 / 5 / 3 / 6;}
#trc {grid-area: 1 / 5 / 2 / 6;}
#tri {grid-area: 2 / 4 / 3 / 5;}

/* Bottom left */
#blh {grid-area: 5 / 2 / 6 / 3;}
#blv {grid-area: 4 / 1 / 5 / 2;}
#blc {grid-area: 5 / 1 / 6 / 2;}
#bli {grid-area: 4 / 2 / 5 / 3;}

/* Bottom right */
#brh {grid-area: 5 / 4 / 6 / 5;}
#brv {grid-area: 4 / 5 / 5 / 6;}
#brc {grid-area: 5 / 5 / 6 / 6;}
#bri {grid-area: 4 / 4 / 5 / 5;}