/** Misc styles for toolbox talk */

/* ######### */
/* # Start # */
/* ######### */
/* Div container for elements */
#start-toolbox-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
/* Toolbox talk button options */
#start-toolbox-container > ion-button {
    margin: 0;
    width: 220px;
    align-self: center;
}

/* ############ */
/* # Rollcall # */
/* ############ */
/* Spacing heading from input above it */
#attendee-heading {
    margin-top: 8px;
}



/* ######################################## */
/* # SiteObservations and IncidentReports # */
/* ######################################## */
/* Form element wrapping list, add button and comments */
.toolbox-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
/* List of site observations */
.toolbox-list {
    padding: 0;
}
/* Grid layout for checkbox and long text */
.long-text-grid {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    width: 100%;
}
/* Long title text of expanding item */
.long-text-grid span {
    width: calc(100% - 30px);
    padding: 5px 0 5px 0;
}

/* Expanding div with item details */
.expanded-actions {
    width: 100% ;
}
/* Unordered list containing actions */
.expanded-actions ul {
    margin: 10px 0 10px 0;
}
/* Review button */
.expanded-actions ion-button {
    margin: 0 0 8px 0;
    width: 50%;
}