/* Swiper container */
.img-doc-container {
    padding: 5px 10px 5px 10px !important;
    width: 100%;
}

/* Image within container */
.img-doc-container img {
    height: auto;
    box-shadow: 0 0 5px var(--primaryBPTint);
}