.risk-indicator {
    margin:2px;
    font-size: 1.1rem;
    width:2.6em;
    height:2.6em;
    line-height: 2.6em;
    border-color: black;
    border-width: 1px;
    border-radius: 4px;
    text-align: center;    
    flex-shrink: 0;
    align-self:flex-end;
 }

 .risk-indicator-large {
    /* margin:10px; */
    font-size: 3rem;
    font-weight:bold;
    width:2em;
    height:2em;
    color: white;
    line-height: 2em;
    border-color: black;
    border-width: 1px;
    border-radius: 4px;
    text-align: center;    
    flex-shrink: 0;
    align-self:flex-end;
 }