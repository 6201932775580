.scanner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.scanner-container canvas {
    height: 100%;
    transform: scale(1.5);
}

.scanner-container div {
    position:absolute;
    text-align: center;
    width: 100%;
    color: white;
    font-size: 1.6rem;
}